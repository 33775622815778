$(function() {

    // Objects
    function Filter_List(id_set) {
        this.id = id_set;
        this.items = {};
    }

    Filter_List.prototype.initialize = function(){
        var max = this.max;
        var items = {};
        _.each(this.id, function(id){
             $("#" + id).data('max_filters', 5 );
            items[id] = $("#" + id).children();
        });

       this.items = items;

    };

    Filter_List.prototype.render =  function(filter_id) {
            var items = this.items;

            if(filter_id === 'all') {
                _.each(this.id, function(id){
                   var max = $("#" + id).data('max_filters');
                    Filter.process(id, max, items[id] );
                    Events.listen(id);
                });
             } else {

                 var matched = _.detect(this.id, function(id) {
                     return filter_id === id;
                 });

                 var max = $("#" + matched).data('max_filters');
                 Filter.process(matched, max, items[matched]);

             }

        };

    // Commands
    var Filter =  {

        process : function(id, max, items) {
                var section_items = items;

                var container = $("#" + id);

                for(var i = 0; i < section_items.length; i++) {
                    if(max < i) {
                        $(section_items[i]).css("display", "none");
                    } else {
                        $(section_items[i]).css("display", "block");
                    }

                }
                if( max < section_items.length) {
                    var total = (section_items.length - max);

                    container.append($('<li class="trigger"><a href="#"> See <strong>' +  total + '</strong> more</a></li>'));
                }

        }
    };
    var Events = {

        listen : function(id) {

                $("#" + id).delegate('.trigger', "click", function() {


                var max = $("#" + id).data('max_filters');

                $("#" + id).data('max_filters', (max + 15)  );

                fl.render(id);
                $(this).remove();
                return false;
            });
        },
        go_home : function() {
            $("#container>header").css("cursor", "pointer");
            $("#container>header").click(function() { location.href = '/'; });
        },
        input_states : function() {
            $('.ti').focus(function() {

                $(this).toggleClass('ti_active');
            });
            $('.ti').blur(function() {
                $(this).toggleClass('ti_active');
            });
            $('.ti_cal').focus(function() {
                $(this).addClass('ti_cal_active');
            });
        }
    };

 // Model
 var fl = new Filter_List(["category_list", "groups_list", "location_list"]);
 fl.initialize();
 fl.render('all');

 // Listeners and initialization
 Events.input_states();
 Events.go_home();

 if(typeof facet_data !== "undefined") {
    $("#search_input-two, #search_input").autocomplete({
        minLength : 3,
        source : facet_data,
        focus : function(event, ui) {
            $("#search_input-two, #search_input").val(ui.item.label);
            return false;
        },
        select : function(event, ui) {
            $("#search_input-two, #search_input").val(ui.item.label);
            var q_field = '';
            switch(ui.item.desc) {
                case 'groups':
                    q_field = 'gf[]';
                    break;
                case 'locations':
                    q_field = 'lf[]';
                    break;
                case 'categories':
                    q_field = 'cf[]';
                    break;
                default:
                    q_field = 'fq';
                    break;

            }


            $("<input />").attr("type", "hidden").attr("name", q_field).val(ui.item.value).appendTo("form[name=manual-search-target-two]");
            $("#search_input-two, #search_input").attr('disabled', 'disabled');
            if(q_field === 'fq') {
              $("form[name=manual-search-target-two]").attr("action", $("form[name=manual-search-target-two]").attr("action").replace("index", "show"));
            }

            $("form[name=manual-search-target-two]").submit();


            return false;
        }
    }).data("ui-autocomplete")._renderItem = function(ul, item) {
        return $("<li></li>").data("ui-autocomplete-item", item)
        .append("<a><span class=\"autocomplete_suggestion\">" + item.label + "</span>  " + item.count + " found in " + item.desc + "</a>")
        .appendTo(ul);
    };

    }
    $("#datepicker").datepicker({
        constrainInput : true,
        maxDate : '+5Y',
        minDate : '-5Y',
        showButtonPanel : true,
        onSelect : function(dateText, inst) {
            $('[name=user_date]').val(dateText);
            $("form[name=input_date]").submit();
        },
        onClose : function() {
             $(this).removeClass('ti_cal_active');
        }
    });

    if($('input[name="user_date"]').val() == "new_date") {
        $("#datepicker").datepicker('setDate', new Date());
    } else {
        $("#datepicker").datepicker('setDate', $('input[name="user_date"]').val());
    }

    $('#datepicker').css('display', 'block');
    $('#html_date').remove();

    var ongoing_sections = $('.ongoing_section');
    _.each(ongoing_sections, function(sec) {
        var num = $(sec).nextUntil(':not(.ongoing_event)').length;

        var section = $(sec).nextUntil(':not(.ongoing_event)').css('display', 'none');
        // var random = Math.floor(Math.random()*num);
        // var section_summary = $("article header h1", section[random]);
//
        // $("ul li.ongoing_feature",sec).html(section_summary.html());
        $("ul li.ongoing_toggle",sec).html('+ See ' + num + ' ongoing events');

        $("ul li.ongoing_toggle",sec).click(function() {
            $(sec).nextUntil(':not(.ongoing_event)').toggle('fade', 400);
            if($("ul li.ongoing_toggle",sec).html() === '- Hide ' + num + ' ongoing events') {
                $("ul li.ongoing_toggle",sec).html('+ See ' + num + ' ongoing events ');
            } else {

                $("ul li.ongoing_toggle",sec).html('- Hide ' + num + ' ongoing events');
            }
        });

    });

});
