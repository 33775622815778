/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import Rails from '@rails/ujs';
Rails.start();

require("@popperjs/core")

import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-datepicker'
import './chosen.jquery.js'
import './script.js'
import $ from 'jquery';

function importAll(r) {
  r.keys().forEach(r);
}
importAll(require.context('../stylesheets/', true, /\.scss$/));
importAll(require.context('../stylesheets/', true, /\.css$/));

global.$ = require("jquery")
require('jquery');
require("jquery-ui")
require("jquery-ui-dist/jquery-ui");
require("underscore")
global._ = require('lodash');
global.$ = $
global.jQuery = $

require.context('../images', true)
const imagePath = (name) => images(name, true)

$(function () {
  $("#datepicker").datepicker();
})
